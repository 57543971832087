import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1b895f3a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex justify-between" }
const _hoisted_2 = { class: "b flex justify-end" }
const _hoisted_3 = {
  key: 0,
  class: "b"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logotype = _resolveComponent("Logotype")!
  const _component_Timer = _resolveComponent("Timer")!
  const _component_Actions = _resolveComponent("Actions")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("div", {
      class: "l flex items-center",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.back && _ctx.back(...args)))
    }, [
      _createVNode(_component_Logotype, { q: true })
    ]),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.role === 'EXPERT')
        ? (_openBlock(), _createBlock(_component_Timer, {
            key: 0,
            seconds: _ctx.seconds
          }, null, 8, ["seconds"]))
        : _createCommentVNode("", true),
      (_ctx.showActions)
        ? (_openBlock(), _createBlock(_component_Actions, {
            key: 1,
            onPlay: _ctx.onPlay,
            onStop: _ctx.onStop,
            onPause: _ctx.onPause,
            isPaused: _ctx.isPaused,
            role: _ctx.role,
            isStarted: _ctx.isStarted
          }, null, 8, ["onPlay", "onStop", "onPause", "isPaused", "role", "isStarted"]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.studentQuestionnaire)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, " Работа не отправлена на проверку! "))
      : _createCommentVNode("", true)
  ]))
}