
import { defineComponent } from "vue-demi";
import zero from "@/filters/zero.filter";

export default defineComponent({
  name: "QuestionnaireTimer",
  props: {
    seconds: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    time() {
      const seconds = zero(this.seconds % 60);
      const minutes = zero(Math.floor(this.seconds / 60));
      return `${minutes}:${seconds}`;
    },
  },
});
