
import { defineComponent } from "vue-demi";

enum Actions {
  play = "play",
  pause = "pause",
  stop = "stop",
}

export default defineComponent({
  name: "QuestionnaireActions",
  props: {
    isPaused: Boolean,
    isStarted: Boolean,
    role: String,
  },
  emits: ["play", "stop", "pause"],
  data() {
    return {
      actions: {
        play: "Начать работу",
        pause: "Приостановить работу",
        stop: "Приостановить работу и выйти",
      },
      showHelp: false,
      currentAction: "" as keyof typeof Actions,
    };
  },
  methods: {
    onShowHelp(action: keyof typeof Actions) {
      this.currentAction = action;
      this.showHelp = true;
    },
  },
});
