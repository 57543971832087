
import { defineComponent } from "vue-demi";

export default defineComponent({
  name: "ExpertMain",
  props: {
    q: {
      type: Boolean,
      default: false
    }
  },
});
